<button
  mat-icon-button
  [matMenuTriggerFor]="menu"
  [disabled]="disabled"
  id="cntxt_menu_btn_{{ icon }}{{!!index ? '_' + index : ''}}"
  (click)="clickEvent.emit(); $event.stopPropagation(); $event.preventDefault()"
>
  <mat-icon>{{ icon }}</mat-icon>
</button>
<mat-menu #menu="matMenu">
  <ng-content> </ng-content>
</mat-menu>
